import "regenerator-runtime/runtime";

import './components/HomeHero';
import PageHeader from './lib/PageHeader';
import './components/PlayVideo';
import scrollToContainingBlock from './lib/ScrollToContainingBlock.js';
import './lib/Inview';
import './lib/FixedContactForm';
import './lib/FixedContactFormExpand';
import './components/GoBack';
import './components/AlertBar';
import './components/BodyPadding';
import './components/Sliders';
import './components/LazyLoadVideo';
import './components/hero-menu';
import './components/promotions';
import './components/RestrictToState';
import './components/PrivacyForm';
import './components/content-navigation';
import './components/overlayImgToggle'
import './components/simple-accordion';
import './components/contact-success-header';
import { initPromoAlert } from './components/PromoAlert';
import Modal from "./lib/Modal";
import { initShowMorePosts } from './components/ShowMorePosts.js';
import SiteHeader from './components/site-header.js';

window.addEventListener('DOMContentLoaded', () => {
    // dynamically load calculator app
    if(document.getElementById('calculator-app')) {
        import(/* webpackChunkName: "calculatorApp" */ './components/calculator-app');
    }

    // dynamically load quicklinks
    if(document.querySelectorAll('[data-quick-links]').length) {
        import(/* webpackChunkName: "quickLinks" */ './lib/QuickLinks').then(({default : initQuickLinks}) => {
            initQuickLinks();
        });
    }

    // dynamically load TestimonialsMasonry
    if(document.querySelectorAll('.masonry').length) {
        import(/* webpackChunkName: "testimonialsMasonry" */ './components/TestimonialsMasonry');
    }

    // dynamically load Vidyard
    if(document.querySelectorAll('[data-vidyard-embed]').length) {
        import(/* webpackChunkName: "vidyard" */ './lib/Vidyard').then(({default: Vidyard}) => {
            /* init Vidyard */
            Vidyard.initVideos();
        });
    }

    // dynamically load YouTube
    if(document.querySelectorAll('[data-youtube-embed]').length) {
        import(/* webpackChunkName: "youtube" */ './lib/YouTube').then(({default: YouTube}) => {
            /* init YouTube */
            YouTube.initVideos();
        });
    }

    // Page Header
    let options = {
        container: document.querySelector('[page]') || document.querySelector('html'),
        el: document.querySelector('[page-header]'),
        toggle_el: document.querySelector('[page-header-hamburger]'),
        menu_item_els: document.querySelectorAll('[page-header-menu-item]'),
        search_toggle_el: document.querySelector('[page-header-search-toggle]')
    }
    
    if (options.el) {
        let page_header = new PageHeader(options);
    }

    /* init tabsets */
    let tabsets = document.querySelectorAll('[data-tabset]');
    let tabset_blocks = document.querySelectorAll('[data-tabset-block]');
    // dynamically load tabset
    if(tabsets.length || tabset_blocks.length) {
        import(/* webpackChunkName: "tabset" */ './lib/Tabset').then(({default: Tabset}) => {
            tabsets.forEach((tabset) => {
                new Tabset(tabset, {
                    transition_time: 300,
                    onTabOpen: function() {
                        scrollToContainingBlock(this.tabset_el);
                    }
                });
            });

            tabset_blocks.forEach((tabset) => {
                new Tabset(tabset, {
                    transition_time: 0
                });
            });
        });
    } 

    // Expandsets
    let expandsets = document.querySelectorAll('[data-expandset]');
    // dynamically load Expandset
    if(expandsets.length) {
        import(/* webpackChunkName: "expandset" */ './lib/Expandset').then(({default: Expandset}) => {
            expandsets.forEach(expandset => {
                new Expandset(expandset, {
                    accordion: true
                });
            });
        });
    }    

    // Dropdowns
    let dropdowns = document.querySelectorAll("[data-control='dropdown']");
    // dynamically load Dropdowns
    if(dropdowns.length) {
        import(/* webpackChunkName: "dropdown" */ './lib/Dropdown').then(({default: Dropdown}) => {
            dropdowns.forEach(dropdown => {
                new Dropdown(dropdown);
            });
        });
    } 

    // dynamically load ContactForm and Newsletter form if form is inview
    if ("IntersectionObserver" in window) {
        let contact_form = document.getElementById('contact-form-top');
        let newsletter_form = document.getElementById('newsletter-form-block');
        let form_observer = new IntersectionObserver(function(entries) {
            entries.forEach((entry) => {
                if (entry.isIntersecting && entry.target.id === 'contact-form-top') {
                    import(/* webpackChunkName: "contactForm" */ './components/ContactForm').then(({default: ContactForm}) => {
                        new ContactForm('contact-form');
                    });
                    form_observer.unobserve(entry.target);
                }

                if (entry.isIntersecting && entry.target.id === 'newsletter-form-block') {
                    import(/* webpackChunkName: "newsletterSignup" */ './components/NewsletterSignup').then(({default: NewsletterSignup}) => {
                        new NewsletterSignup('newsletter-signup');
                    });
                    form_observer.unobserve(entry.target);
                }
            });
        });

        if(contact_form) {
            form_observer.observe(contact_form);
        }

        if(newsletter_form) {
            form_observer.observe(newsletter_form);
        }
    }

    // dynamically load tippy
    if(document.querySelectorAll('[data-tippy-content]')) {
        import(/* webpackChunkName: "tippy" */ 'tippy.js').then(({default: tippy, roundArrow}) => {
            /* tooltips via tippy.js https://atomiks.github.io/tippyjs/ */
            tippy('[data-tippy-content]', {
                animation: "scale-subtle",
                theme: "yellow",
                arrow: roundArrow
            });
        });
    }

    /* init post show more button */
    initShowMorePosts();
});

/* init promo alert */
initPromoAlert();

SiteHeader.init();

/* init tabs */
const modals = document.querySelectorAll('[data-video-modal]');
modals.forEach((modal) => {
    new Modal(modal, {
        onOpen(toggle) {
            const videoId = toggle?.dataset?.videoId;
            const videoContainer = this.modal_el.querySelector('[data-video-modal-video]');

            if(videoContainer && videoId) {
                let iframe = document.createElement('iframe');
                iframe.src = `//play.vidyard.com/${videoId}.html`;
                iframe.setAttribute('width', '640');
                iframe.setAttribute('height', '360');
                iframe.setAttribute('scrolling', 'no');
                iframe.setAttribute('frame-border', '0');
                iframe.setAttribute('allowtransparency', 'true');
                iframe.setAttribute('allowfullscreen', 'true');
                iframe.setAttribute('autoplay', 'true');

                videoContainer.appendChild(iframe);
            }
        },

        onClose() {
            const videoContainer = this.modal_el.querySelector('[data-video-modal-video]');

            if(videoContainer) {
                videoContainer.innerHTML = '';
            }
        }
    });
});